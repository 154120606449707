import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const allowedEnvs = ['production', 'staging']

type ConfigureProps = {
  applicationId: string
  clientToken: string
  version: string
  service: string
}
type ConfigureDataDog = (props: ConfigureProps) => void

const configureDataDogRum: ConfigureDataDog = ({
  applicationId,
  clientToken,
  version,
  service,
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service,
    version,
    sessionSampleRate: 70,
    sessionReplaySampleRate: 30,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
  })

  datadogRum.startSessionReplayRecording()
}

const configureDataDogLogs: ConfigureDataDog = ({
  clientToken,
  version,
  service,
}) => {
  datadogLogs.init({
    clientToken,
    version,
    service,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 30,
  })
}

function startMonitoring() {
  const appEnv = process.env.APP_ENV
  const applicationId = process.env.DATADOG_APPLICATION_ID
  const clientToken = process.env.DATADOG_CLIENT_TOKEN
  const version = process.env.DATADOG_SERVICE
  const service = process.env.DATADOG_SERVICE

  const config = {
    applicationId,
    clientToken,
    version,
    service,
  }

  if (allowedEnvs.includes(appEnv)) {
    configureDataDogLogs(config)
    configureDataDogRum(config)
  }
}

export { startMonitoring }
